/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.embla-thumbs {
  --thumbs-slide-spacing: 11px;
  --thumbs-slide-height: 95px;
  margin-top: var(--thumbs-slide-spacing);
  padding-left: 32px;
  padding-right: 25px;
  position: relative;
}
@media (min-width: 48em) {
  .embla-thumbs {
    display: none;
  }
}
.embla-thumbs__viewport {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.embla-thumbs__container {
  display: flex;
  flex-direction: row;
  margin-left: calc(var(--thumbs-slide-spacing) * -1);
}
.embla-thumbs__slide {
  flex: 0 0 33.3333%;
  min-width: 0;
  padding-left: var(--thumbs-slide-spacing);
  position: relative;
}
/* @media (min-width: 576px) {
  .embla-thumbs__slide {
    flex: 0 0 18%;
  }
} */
.embla-thumbs__slide__button {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: block;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  opacity: 0.7;
  transition: opacity 0.2s;
}
.embla-thumbs__slide--selected .embla-thumbs__slide__button {
  opacity: 1;
}
.embla-thumbs__slide__img {
  display: block;
  height: var(--thumbs-slide-height);
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}
@media (min-width: 30em) {
  .embla-thumbs__slide__img {
    height: 160px;
  }
}
.embla-thumbs__slide__number {
  width: 3rem;
  height: 3rem;
  z-index: 1;
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 3rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla-thumbs__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.4rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.embla-thumbs__arrows {
  display: flex;
  top: calc(50% - 7px);
  position: absolute;
  justify-content: space-between;
  width: calc(100% - 34px);
}
.embla-thumbs__arrows button:first-child {
  margin-left: -23px;
}
.embla-thumbs__arrows button:last-child img {
  transform: rotate(180deg);
}
.playIcon {
  position: absolute;
  top: calc(50% - 14px);
  left: calc(50% - 13.5px + 5.5px);
}
.specifications button:hover, .specifications button:focus, .count button:hover, .count button:focus {
  background: #949182!important;
}
.specifications input, .count input {
  padding-inline-start: 0!important;
  padding-inline-end: 0!important;
  color: black!important;
  font-weight: 500!important;
  opacity: 1!Important;
  cursor: default!important;
}
.specifications input:hover, .specifications input:focus, .specifications input:focus-visible,
.count input:hover, .count input:focus, .count input:focus-visible {
  border-color: transparent!important;
  box-shadow: none!important;

}
.inc {
  border-bottom-left-radius: 0!important;
  border-top-left-radius: 0!important;
  border-bottom-right-radius: 9px!important;
  border-top-right-radius: 9px!important;
  border: 0!important;
}
.dec {
  border-bottom-left-radius: 9px!important;
  border-top-left-radius: 9px!important;
  border-bottom-right-radius: 0px!important;
  border-top-right-radius: 0px!important;
  border: 0!important;
}
.btnBuy {
  background: #4E4734!important;
  color: #F1EBDE!important;
  font-weight: 500!important;
}
.btnBuy:hover {
  background: #312c20!important;
  color: #F1EBDE!important;
  font-weight: 500!important;
}
.burger.active::before {
  rotate: -45deg;
  top: 5px!important;
}
.burger.active::after {
  margin-top: 4px!important;
  rotate: 45deg;
}

.progress__stage{
  display: flex;
  justify-content: space-between;
  align-items:center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.progress__stage-step{
  background-color: transparent;
  border: 1px solid #949182;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25em;
}


.progress__stage-line{
  width: 5%;
  margin:8px 10px;
  border-color: #949182;
}

.progress__stage-step-number{
  font-weight: 400;
  font-size: 16px;
  color: #6F6F6F;
}
.progress__stage-step:first-child{
  border-color: #D7D5CC;
}
.progress__stage-step:first-child p {
  color: #D7D5CC;
}
.progress__stage-step:first-child + .progress__stage-line {
  border-color: #D7D5CC;
}
.progress__stage-step.completed{
  background-color: #949182;
}

.progress__stage-step.completed .progress__stage-step-number{
  color: #F1EBDE;
}

.progress__stage-step.active{
  background-color: white;
}

.progress__stage-step.active .progress__stage-step-number{
  font-weight: 500;
  color: #000000;
}
.progress__stage-step-name {
  display: none;
}
.progress__stage-step:first-child svg {
  display: none;
}
#saferoute-cart-widget iframe {
  border: 0!important;
  border-radius: 10px!important;
}
@media (min-width: 62em) {
  .progress__stage {
    width: calc(100% - 30px);
    margin-bottom: 20px;
  }
  .progress__stage-step-name {
    display: block;
  }
  .progress__stage-step-number {
    display: none;
  }
  .progress__stage-step {
    width: auto;
    height: auto;
    flex: 0 0 25%;
    justify-content: flex-start;
    background-color: transparent!important;
    border: 0;
    border-bottom: 1px solid #949182;
    border-radius: 0;
    padding-bottom: 15px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: #949182;
    margin-right: 10px;
  }
  .progress__stage-step:last-child {
    margin-right: 0;
  }
  .progress__stage-line {
    display: none;
  }
  .progress__stage-step.completed {
    border-bottom: 1px solid #4E4734;
  }
  .progress__stage-step.completed .progress__stage-step-name{
    color: #4E4734;
  }
  .progress__stage-step:first-child{
    border-color: #949182;
  }
  .progress__stage-step:first-child p {
    color: #949182;
    padding-left: 5px;
  }
  .progress__stage-step:first-child svg {
    display: block;
  }
  .progress__stage-step:first-child + .progress__stage-line {
    border-color: #949182;
  }
}